<template>
  <div class="home ma-2">
    <v-card class="ma-2">
      <v-card-title>Teste App</v-card-title>
      <v-card-text>

          <v-card>
            <v-card-title> Intervalo </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Início"
                    outlined
                    type="datetime-local"
                    clearable
                    v-model="report.from"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Final"
                    outlined
                    type="datetime-local"
                    clearable
                    v-model="report.to"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <p class="mx-2">Posições {{route.length}}</p>
                <p class="mx-2" >Distancia Total {{totalDistance}}</p>
                <v-spacer></v-spacer>
                <v-btn @click="getPositions">Buscar</v-btn>
              </v-row>
            </v-card-text>
          </v-card>


        <div
          id="mapContainer"
          ref="ctn"
          class="d-flex align-self-stretch"
          :style="`height: 50vh; z-index:3`"
        ></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker"
import L from "leaflet";
import { Icon } from "leaflet";
export default {
  name: "TesteApp",
  data() {
    return {
      map: null,
      route: [],
      markers: [],
      polyline: null,
      report: {},
      icone: null,
      totalDistance: 0
    };
  },
  methods: {
    getPositions() {
      console.log(this.report);
      this.$http.post("testeApp/position", this.report).then((resp) => {
        this.route = resp.data;
        console.log({first: resp.data[0], last: resp.data[resp.data.length -1], count: resp.data.length });
        this.showRoute();
        this.totalDistancF();
      });
    },
    totalDistancF(){
      if(this.route.length){
        this.totalDistance =  this.route[this.route.length -1].totalDistance
      }
      this.totalDistance =  0
    },
    showRoute() {
      let cords = [];
      if (!this.route.length) {
        return;
      }

      this.markers.forEach((e) => {
        e.remove(this.map);
      });

      if (this.polyline != null) {
        this.polyline.remove(this.map);
      }

      this.markers = [];

      this.route.forEach((e) => {
        let b = L.marker([e.latitude, e.longitude], {icon: this.icone, rotationAngle: e.bearing})
         .bindTooltip(
            `
            <table>
                <tr>
                    <td>Velocidade:</td>
                    <td style="text-align: center;">${(e.speed).toFixed(2)} Km/h</td>
                </tr>
                <tr>
                    <td>Data:</td>
                    <td style="text-align: center;">
                      ${new Date(e.createdAt).toLocaleString()}
                    </td>
                </tr>
                <tr>
                    <td>Distancia do ponto anterior:</td>
                    <td style="text-align: center;">
                      ${e.distance} Metros
                    </td>
                </tr>
                <tr>
                    <td>Distancia Total:</td>
                    <td style="text-align: center;">
                      ${e.totalDistance} Metros
                    </td>
                </tr>
            </table>
            `
          )
        .addTo(this.map);

        cords.push([e.latitude, e.longitude]);

        this.markers.push(b);
      });

      if (cords.length) {
        this.polyline = L.polyline(cords).addTo(this.map);
        this.map.fitBounds(cords);
      }
    },
    initMap() {
      if (this.map != null) {
        return;
      }
      // let iconURL = '@/assets/vtrStv.png'
      let iconCustomSize = 60;
      this.icone = L.icon({ iconUrl: "http://localhost:3333/assets/image/marker.png", iconSize: [iconCustomSize * 0.75, iconCustomSize], iconAnchor: [(iconCustomSize * 0.75) / 2, iconCustomSize / 2] });
      // this.icone = L.icon({ iconUrl: "http://localhost:3333/assets/image/vtr.png", iconSize: [36, 36], iconAnchor: [18, 18] });
      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
      this.map = L.map("mapContainer").setView(
        [-14.337566608887226, -54.93164062500001],
        4
      );
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" + this.$store.getters.getMapBoxKey,
        {
          maxZoom: 18,
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          id: "mapbox/streets-v11",
          tileSize: 512,
          zoomOffset: -1,
        }
      ).addTo(this.map);
    },
  },
  created() {
    setTimeout(() => {
      this.initMap();
    }, 500);
  },
};
</script>